import React from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent from "react-cookie-consent";

export default function GDPRConsent() {
  const location = useLocation();
  return (
    <CookieConsent
      location="bottom"
      cookieName="gatsby-gdpr-google-tagmanager"
      style={{ background: '#000', padding: '15px' }}
      buttonText="Accept"
      buttonStyle={{
        backgroundColor: '#FFF',
        color: '#000',
        fontSize: '13px',
        borderRadius: '5px',
      }}
      enableDeclineButton
      declineButtonText="Decline"
      declineButtonStyle={{
        backgroundColor: '#555',
        color: '#efefef',
        fontSize: '13px',
        borderRadius: '5px',
      }}
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      This website uses cookies to enhance the user experience. <br />Your privacy is important to us, and we will never sell your data. <br /><a style={{ textDecoration: 'underline' }} href="/privacy">Privacy Policy</a>
    </CookieConsent>
  )
}
